import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AesShipmentKeys from 'src/constants/locale/key/AesShipment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const errMsgAlphaNumericSpace = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_SPACE
  )
  const errMsgAlphaNumericWithSpecialChar = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_ALPHA_NUMERIC_WITH_SPECIAL_CHAR
  )

  const errMsgNumeric = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_NUMERIC
  )

  const errMsgMinLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MIN_LENGTH
  )
  const errMsgMaxLength = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_MAX_LENGTH
  )
  const errMsgFTZFormat = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_FTZ_FORMAT
  )
  const errMsgFTZMandatory = translate(
    Namespace.AES_SHIPMENT,
    AesShipmentKeys.ValidationMessage.ERROR_MSG_FTZ_MANDATORY
  )

  const regexNumeric = '^[0-9]*$'
  const regexAlphaNumericSpace = '^[a-zA-Z0-9 ]*$'
  const regexAlphaNumericWithSpecialChar =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const regexForeignTradeZone = '^\\d{3}[a-zA-Z0-9]{6}$'

  return Yup.object({
    //id: Yup.string().nullable(),
    //corpid: Yup.string().nullable(),
    relatedCompanyInd: Yup.string().nullable(),
    usToPuertoRicoInd: Yup.string().nullable(),
    puertoRicoToUsInd: Yup.string().nullable(),
    foreignPrincipalPartyInd: Yup.string().nullable(),
    hazmatInd: Yup.string().nullable(),
    toBeSoldEnRouteInd: Yup.string().nullable(),
    filingTypeInd: Yup.string().nullable().required(requiredMessage),
    aeiFilingType: Yup.string()
      .when('filingTypeInd', {
        is: (value) => value == '3',
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().nullable()
      }).nullable(),
    shipmentReferenceNumber: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(17, errMsgMaxLength + " 17"),
    estimatedExportDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable().required(requiredMessage),
    portOfExportation: Yup.string().nullable().required(requiredMessage),
    portOfUnlading: Yup.string()
      .when('modeOfTransport', {
        is: (value) => value == '10' || value == '11' || value == '12',
        then: Yup.string().nullable().required(requiredMessage),
        otherwise: Yup.string().nullable()
      }).nullable(),
    aesInternalTransactionNumber: Yup.string().nullable(),
    foreignTradeZone: Yup.string().nullable().matches(regexForeignTradeZone, errMsgFTZFormat).max(9, errMsgMaxLength + " 9")
    .when('inBondType', {
      is: (inBondType) => (inBondType == '67' || inBondType == '68'),
      then:  Yup.string().required(errMsgFTZMandatory).nullable().matches(regexForeignTradeZone, errMsgFTZFormat)
    }),
    entryNumber: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(15, errMsgMaxLength + " 15"),
    usStateOfOrigin: Yup.string().nullable().required(requiredMessage),
    ultimateDestinationCountry: Yup.string().nullable().required(requiredMessage),
    modeOfTransport: Yup.string().nullable().required(requiredMessage),
    totalShippingWeight: Yup.string().nullable(),
    carrierCode: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(4, errMsgMaxLength + " 4"),
    carrierVesselName: Yup.string().nullable().required(requiredMessage).matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(23, errMsgMaxLength + " 23"),
    //responseDatetime: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    actionCode: Yup.string().nullable(),
    status: Yup.string().nullable(),
    inBondType: Yup.string().nullable().required(requiredMessage),
    shipperName: Yup.string().nullable(),
    consigneeName: Yup.string().nullable(),
    //ftpUserId: Yup.string().nullable(),
    originalItn: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(15, errMsgMaxLength + " 15"),
    //modeOfSubmission: Yup.string().nullable(),
    /*oriCreatedBy: Yup.string().nullable(),
    oriUpdatedBy: Yup.string().nullable(),
    oriSubmittedBy: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    updatedBy: Yup.string().nullable(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    submittedBy: Yup.string().nullable(),
    submittedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage).nullable(),
    version: Yup.string().nullable(),
    partyId: Yup.string().nullable(),*/
    transportationDetails: Yup.array(
      Yup.object({
        equipmentNumber: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(14, errMsgMaxLength + " 14"),
        sealNumber: Yup.string().nullable().matches(regexAlphaNumericSpace, errMsgAlphaNumericSpace).max(15, errMsgMaxLength + " 15"),
        transportationReferenceNumber: Yup.string().nullable().matches(regexAlphaNumericWithSpecialChar, errMsgAlphaNumericWithSpecialChar).max(30, errMsgMaxLength + " 30"),
      })
    ),
    partyDetails: Yup.array(),
    commodityDetails: Yup.array(),
    usedVehicleDetails: Yup.array(),
    pgaDetails: Yup.array(),
  })
}

export default makeValidationSchema

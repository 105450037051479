import React, { useEffect, useState, useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
  components
} from 'cng-web-lib'
import { NaAesPOEPortAutocompleteField, NaAesPOUPortAutocompleteField, NaAesStateCodeAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { useFormContext } from 'react-hook-form'
import { AesTranslationContext } from '../../contexts/AESTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

function ShipmentHeaderSection({ disabled, showNotification, shouldHideMap }) {
  const { getTranslatedText } = useContext(AesTranslationContext)

  const { setValue, watch } = useFormContext();
  const filingTypeInd = watch("filingTypeInd")
  const modeOfTransport = watch("modeOfTransport")
  const inBondType = watch("inBondType")

  const [aeiFilingTypeDisabled, setAeiFilingTypeDisabled] = useState(false);
  const [aeiFilingTypeRequired, setAeiFilingTypeRequired] = useState(false);
  const [portOfUnladingRequired, setPortOfUnladingRequired] = useState(false);

  useEffect(() => {

    //MOT = Barge, Vessel Containerized and Vessel Non Containerized set Port of Unlading as mandatory 
    if (modeOfTransport == "12" || modeOfTransport == "11" || modeOfTransport == "10") {
      setPortOfUnladingRequired(true)
    } else {
      setPortOfUnladingRequired(false)
    }

  }, [modeOfTransport]);

  useEffect(() => {

    if (filingTypeInd === "3") {
      setAeiFilingTypeDisabled(false);
      setAeiFilingTypeRequired(true);

    } else {
      setAeiFilingTypeDisabled(true);
      setAeiFilingTypeRequired(false);

      setValue("aeiFilingType", "")
    }

  }, [filingTypeInd]);

  return (
    <Grid item xs={12}>
      <CngSection
        title={getTranslatedText('AES', 'headerTitle')}
        subheader={
          <Typography color='textSecondary' variant='caption'>
            <Typography variant='inherit' color='error'>*</Typography>
            {getTranslatedText('AES', 'mandatoryField')}
          </Typography>
        }
        id="shipment-header"
      >
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.modeOfTransport}>
            <CngCodeMasterAutocompleteField
              name="modeOfTransport"
              label={getTranslatedText('AES', 'modeOfTransport')}
              disabled={disabled}
              size='small'
              codeType='AES_MODE_OF_TRANSPORT'
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.carrierCode}>
            <CngTextField
              name="carrierCode"
              label={getTranslatedText('AES', 'carrierCode')}
              disabled={disabled}
              size='small'
              required
              onChange={(e) => {
                setValue('carrierCode', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('carrierCode', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.aesInternalTransactionNumber}>
            <CngTextField
              name="aesInternalTransactionNumber"
              label={getTranslatedText('AES', 'aesInternalTransactionNumber')}
              disabled={true}
              size='small'
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.filingTypeInd}>
            <CngCodeMasterAutocompleteField
              name="filingTypeInd"
              label={getTranslatedText('AES', 'filingTypeInd')}
              disabled={disabled}
              size='small'
              codeType='AES_FILING_TYPE_INDICATOR'
              required
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.aeiFilingType}>
            <CngCodeMasterAutocompleteField
              name="aeiFilingType"
              label={getTranslatedText('AES', 'aeiFilingType')}
              disabled={aeiFilingTypeDisabled}
              size='small'
              codeType='AES_AEI_FILING_TYPE'
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
              required={aeiFilingTypeRequired}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.foreignTradeZone}>
            <CngTextField
              name="foreignTradeZone"
              label={getTranslatedText('AES', 'foreignTradeZone')}
              disabled={disabled}
              size='small'
              required={inBondType === '67' || inBondType === '68'}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('foreignTradeZone', e.target.value.trim().toUpperCase(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.shipmentReferenceNumber}>
            <CngTextField
              name="shipmentReferenceNumber"
              label={getTranslatedText('AES', 'shipmentReferenceNumber')}
              disabled={disabled}
              size='small'
              required
              onChange={(e) => {
                setValue('shipmentReferenceNumber', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('shipmentReferenceNumber', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.inBondType}>
            <CngCodeMasterAutocompleteField
              name="inBondType"
              label={getTranslatedText('AES', 'inBondType')}
              disabled={disabled}
              size='small'
              codeType='AES_INBOND_TYPE'
              required
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.estimatedExportDate}>
            <CngDateField
              name="estimatedExportDate"
              label={getTranslatedText('AES', 'estimatedExportDate')}
              disabled={disabled}
              size='small'
              required
            />
          </CngGridItem>
         
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.portOfExportation}>
            <NaAesPOEPortAutocompleteField
              name="portOfExportation"
              label={getTranslatedText('AES', 'portOfExportation')}
              disabled={disabled}
              size='small'
              required
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.portOfUnlading}>
            <NaAesPOUPortAutocompleteField
              name="portOfUnlading"
              label={getTranslatedText('AES', 'portOfUnlading')}
              disabled={disabled}
              size='small'
              required={portOfUnladingRequired}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.entryNumber}>
            <CngTextField
              name="entryNumber"
              label={getTranslatedText('AES', 'entryNumber')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue('entryNumber', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('entryNumber', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>   

          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.usStateOfOrigin}>
            <NaAesStateCodeAutoCompleteField
              name="usStateOfOrigin"
              label={getTranslatedText('AES', 'usStateOfOrigin')}
              disabled={disabled}
              size='small'
              required
              countryCode='US'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.ultimateDestinationCountry}>
            <CngCountryAutocompleteField
              name="ultimateDestinationCountry"
              label={getTranslatedText('AES', 'ultimateDestinationCountry')}
              disabled={disabled}
              size='small'
              required
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.originalItn}>
            <CngTextField
              name="originalItn"
              label={getTranslatedText('AES', 'originalItn')}
              disabled={disabled}
              size='small'
              onChange={(e) => {
                setValue('originalItn', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('originalItn', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
        </Grid>
      </CngSection>
    </Grid>
  )
}

export default ShipmentHeaderSection
